.section-services {
  background: #fff;
}

.addo-feature {
  position: relative;

  float: left;

  width: 100%;
  min-height: 300px;
  margin-bottom: 30px;
  padding: 30px;

  border: 1px solid #ececec;

  text-align: center;

  &:hover {
    position: relative;
    transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
    transform: translateY(-10px);
  }
}

.addo-feature .addo-icon {
  text-align: center;
  background: transparent;
  margin-bottom: 10px;
}

.icon {
  font-size: 35px;
}

/*
.addo-feature .addo-text {
  width: 100%;
}

.addo-feature .addo-text h2,
.addo-feature .addo-text h3 {
  margin: 0;
  padding: 0;
}

.addo-feature .addo-text h3 {
  font-weight: 700;
  margin-bottom: 10px;
  color: #101010;
  font-size: 18px;
  font-family: "Rambla", sans-serif;
}*/
/*
.addo-feature.addo-feature-sm .addo-text {
  margin-top: 6px;
}

.addo-feature.addo-feature-sm .addo-icon i {
  color: #101010;
  font-size: 40px;
}*/
/*
@media screen and (max-width: 1200px) {
  .addo-feature.addo-feature-sm .addo-icon i {
    font-size: 28px;
  }
}
*/

.addo-feature {
  .addo-text {
    width: 100%;

    h2 {
      margin: 0;
      padding: 0;
    }

    h3 {
      margin: 0;
      padding: 0;
      font-weight: 700;
      margin-bottom: 10px;
      // color: #101010;
      //font-size: 18px;
      //font-family: "Rambla", sans-serif;
    }
  }

  /*  &.addo-feature-sm {
    .addo-text {
      margin-top: 6px;
    }

    .addo-icon i {
      color: #101010;
      font-size: 40px;
    }
  }*/
}

.service-detail {
  // Main properties
  //
  &-title {
    //margin-bottom: 1.5rem;
  }

  &-image {
    display: block;

    width: 100%;
    height: 100%;

    margin-left: auto;
    margin-right: auto;

    img {
      // 12/05 - fix
      // Пропорциональное масштабирование изображений с атрибутами размерностей.
      width: auto;
      max-width: 100%;
      height: auto;
    }
  }

  // Typography
  //
  .service-detail-title {
    h2 {
      //      font-weight: bold !important;
    }
  }

  &-textlist {
    text-align: left;
  }

  p,
  ul,
  ou,
  li {
    //line-height: 2rem;
  }
}
