

a,
a:active,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

.sidebar1 {
  float: left;
  display: block;
  position: sticky;
  top: 0;
  //bottom: 0;
  //left: 0;
  min-height: 800px;
  width: 100%;
}

.sidebar {
  display: block;
  //  position: sticky;
  //  position: -webkit-sticky;
  //  top: 0;
  //  left: 0;
  min-height: 2em;
  width: 100%;
}

.row-flex {
  display: flex;
  flex-flow: row wrap;
}

.sticky-top1 {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  border: 1px solid #000;
}

.sticky {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.sidebar-box {
  margin-bottom: 40px;
  padding: 0 25px;
  font-size: 15px;
  width: 100%;

  h3.sidebar-heading {
    font-size: 20px;
    font-weight: normal;
    font-style: italic;
    margin-bottom: 30px;
  }
}



