/**
 * File: contact.scss
 *
 * Option:
 * Author:
 * License:
*/

// Required
@import "../utilities/variables";
@import "../utilities/mixis";
@import "../utilities/functions";

// Optional
@import "../utilities/mediaqueries";
@import "../utilities/themes";

/**
 * Variables
 **/
$element-main-color: theme-colors($theme-main, "primary");
$element-light-color: theme-colors($theme-main, "secondary");
$element-font-text-color: theme-colors("primary", "font-text");

$element-form-gray-color: theme-colors("primary", "gray");
$element-form-shadow-color: theme-colors("primary", "form-shadow");

/**
 * Styles
 **/
.section-contact {
  background: #f4f4f4;
}

.contact {
  .form-control {
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    background: rgb(255, 255, 255);
    border: 1px solid #ddd;
    padding-left: 15px;
    padding-top: 25px;
    padding-bottom: 25px;
    transition: all 0.5s ease-in-out 0.1s;
    -webkit-transition: all 0.5s ease-in-out 0.1s;
    margin-bottom: 20px;
  }
  input.form-control {
    width: 100%;
    float: left;

    @include breakpoint-up(md) {
      width: calc(50% - 10px);
      margin-right: 20px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
