.section-brands {
  z-index: 0;
  background-image: none;
  background-attachment: scroll;
  background-size: auto;
  background-image: url(../../../images/bg-client.jpg);
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
}

.logo_img img {
  opacity: 0.6;
}

.bg-overlay {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  // z-index: 1000;
}

.section-brands .logo_img:not(:hover) [fill*="#"]:not(:focus) {
  fill: #6a727a;
  opacity: 0.8;
}

:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.logo_img img {
  margin: auto;
  //min-height: 180px;
  max-height: 240px;
}

.logo_img img:not(:hover) {
  filter: grayscale(1);
}

.section-brands {
  position: relative;
  // z-index: 0;
  background-image: none;
  background-attachment: scroll;
  background-size: auto;

  background-image: url(../../../images/bg-client.jpg);
  background-position: center center;
  background-attachment: fixed;

  background-size: cover;
  background-position: center center;
}
