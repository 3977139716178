.section-main-hero {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.effects {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
}

.main-hero {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .container-fluid {
    z-index: 2;
    color: #ffffff;
  }

  &-content {
    //display: table-cell;
    display: inline;
    min-height: 700px;
    position: relative;
    //bottom: 0px;
    //left: 0;

    padding: 2rem;

    background: transparent;

    text-align: center;
    vertical-align: middle;
  }

  &-title {
    color: #fff;
    margin: 0;
    margin-bottom: 2rem;
  }

  &-subtitle {
    color: #fff;
    margin: 0;
    margin-bottom: 1.2rem;
  }

  &-typewriter {
    color: #fff;
    margin: 0;

    .Typewriter {
      display: inline-block;
      .typewriter-wrapper {
      }
    }
  }
}

/**
 * Typography
 **/

.main-hero {
  &-title {
    font-size: 45px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: normal;
  }
  &-subtitle {
    font-size: 27px;
    line-height: 1.75em;
    font-family: "Rambla", sans-serif;
    font-weight: 700;
    letter-spacing: -1px;
  }
}
