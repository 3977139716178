.countdown-timer-wrapper {
  // position: relative;
}

.countdown-timer {
  position: absolute;
  top: 28%;

  width: 100%;

  margin: 0 auto;

  color: #fff;
  text-align: center;

  .heading-count {
    padding: 10px 68px;
    text-align: center;
  }

  .button-group {
    margin-top: 30px;
  }

  &-title {
    color: #fff;
    text-transform: uppercase;
  }

  &-subtitle {
    color: #fff;
  }

  p {
    color: #fff;
    font-size: 14px;
    line-height: 22px;
  }
}

.time-countdown {
  width: 100%;
}

.time-count div {
  text-align: center;
}

.time-entry {
  display: inline-block;
  margin: 15px;
  padding: 20px;
  border-radius: 0px;
  text-align: center;
  font-weight: 400;
  color: #fff;
  font-size: 15px;
  line-height: 22px;
  &:first-child {
    border-left: none;
  }
  span {
    font-size: 45px;
    font-family: "Montserrat", sans-serif;
    line-height: 45px;
    font-weight: 700;
    display: block;
    color: #fff;
    margin-bottom: 10px;
  }
}

.countdown-social a {
  display: inline-block;
  color: #fff;
  width: 35px;

  // line-height: 35px;
  font-size: 20px;
  text-align: center;
  transition: all 0.3s ease-in-out 0s;
  &:hover {
    color: #007bff;
  }
}

.bg-wraper {
  position: fixed;
  top: 0;
  height: 100%;
  min-height: 700px;
  width: 100%;
}

.bg-wraper {
  background: url(../../../images/slide1.jpg) no-repeat center center fixed;
  background-size: cover;
}
