.progress-bar {
  position: relative;
  background-color: #ececec;
  height: 3px;
  margin: 0 0 15px 0;

  &-fill {
    position: absolute;
    //height: 3px;
    height: 100%;
  }
}

.skill-box {
  &-title {
    position: relative;
    //font-family: "Rambla", sans-serif;
    font-size: 15px;
    text-align: left;
    margin: 0 0 5px 0;
    color: #101010;
  }
  &-percent {
    position: absolute;
    right: 0;
  }
}

.progress-bar {
  height: 10px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  margin-top: 10px;
  border: 1px solid #e0e0e0;

  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;

  &-fill {
    background: #6092d6;
    background: linear-gradient(to right, #3648ff, #6092d6);

    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    transition: width 0.6s ease;
  }
}
/* Animations for Skills Progress Bar */
